import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const About = () => (
  <Layout>
    <SEO
      title="About Prolacs"
      description="Prolacs is a brand of Konzek Technology Inc. With its 14 years of knowledge and experience"
    />
    <Container>
      <Row className="prolacs-about-us-row">
        <Col sm>
          <div className="prolacs-divider-title">
            <br></br>
            <br></br>
            <h1>About Prolacs</h1>
          </div>
          <p style={{ height: "10vh" }}>
            <b>Prolacs</b> is a global brand of of{" "}
            <a href="https://www.konzek.com">
              <b>Konzek Teknoloji A.Ş. </b>
            </a>
            , specializing in smart control systems and industrial automation
            solutions for the laundry industry. Since 2005, we have been
            developing advanced control computers, sensors, and AI-powered
            laundry management software, helping businesses achieve efficiency,
            sustainability, and operational excellence.
            <br />
            With over 20 years of industry experience, we have produced more
            than 60,000+ control computers and 85,000+ sensors, supplying
            leading machine manufacturers in 70+ countries. Our solutions
            seamlessly integrate with Industry 4.0 technologies, providing
            real-time monitoring, process optimization, and intelligent
            automation.
          </p>
          <br />
          <h4>Innovative Laundry Solutions</h4>
          <br />
          <br />
          Under the <b>Prolacs</b> brand, we offer cutting-edge control systems
          for industrial washing and drying machines, including:
          <br />
          <br />
          <h4>Washing Machine Controllers </h4>
          <ul>
            <li>Soft Mount Laundry Washing Machine Controllers</li>
            <li>Hard Mount Laundry Washing Machine Controllers</li>
            <li>Hygiene Barrier Washing Machine Controllers</li>
            <li>Coin-Operated Washing Machine Controllers</li>
          </ul>
          <h4>Drying Machine Controllers</h4>
          <ul>
            <li>Natural Gas Heating Drying Machine Controllers</li>
            <li>Electric Heating Drying Machine Controllers</li>
            <li>Steam Heating Drying Machine Controllers</li>
          </ul>
          <h4 style={{ padding: "3vh 0 3vh 0" }}>
            Key Features & Technologies
          </h4>
          <p style={{ height: "5vh" }}>
            Our controllers and software solutions are designed to optimize
            efficiency, energy savings, and automation in laundries. Key
            features include:
          </p>
          <ul>
            <li>
              AI-Enhanced Efficiency: Smart automation for optimized washing and
              drying cycles
            </li>
            <li>
              Industry 4.0 Ready: Seamless integration with cloud-based
              monitoring & management systems
            </li>
            <li>
              Custom Design & Compatibility: Tailored controllers & sensors for
              various machine brands
            </li>
            <li>
              Energy & Resource Optimization: Reduced electricity, gas, steam,
              water, and detergent consumption
            </li>
            <li>
              Remote Access & Control: VNC-based remote monitoring and automatic
              parameter adjustments
            </li>
            <li>
              Intelligent Water & Detergent Management: Adaptive level-based &
              weight-based dosing
            </li>
            <li>
              Advanced User Management: Role-based access and multi-language
              support
            </li>
            <li>
              24/7 Global Support: Dedicated customer service & technical
              assistance
            </li>
          </ul>
          <h4 style={{ padding: "3vh 0 3vh 0" }}>Why Prolacs?</h4>
          <ul>
            <li>
              Proven Expertise: 20+ years of innovation in laundry automation &
              control systems
            </li>
            <li>Global Reach: Trusted by leading brands in 70+ countries</li>
            <li>
              High Production Capacity: Over 60,000+ control computers & 85,000+
              sensors produced
            </li>
            <li>
              Smart & Sustainable: AI-powered solutions to reduce costs, improve
              efficiency, and minimize waste
            </li>
            <li>
              End-to-End Solutions: From hardware manufacturing to software
              integration, we cover every aspect of industrial laundry
              automation
            </li>
          </ul>
          <p style={{ height: "10vh" }}>
            At <b style={{ padding: "0 0 0 1vh" }}>Prolacs</b>, we are committed
            to revolutionizing the laundry industry with intelligent,
            future-ready solutions. Explore our innovative laundry automation
            technologies at
            <a
              style={{ padding: "0 1vh 0 1vh" }}
              href="https://www.prolacs.com/"
            >
              prolacs.com
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default About
